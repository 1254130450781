export default class CustumerSupplier {
    constructor() {
        this.id = "";
        this.options = ["isCustomer"];
        this.personRequest = { typePerson: 2, companyName: "", tradeName: "", document: "", stateInscription: "", municipalInscription: "" };
        this.categoryCustomer = { id: "", content: "" };
        this.originCustomer = { id: "", content: "" };
        this.contactRequest = { phone: "", webSite: "", email: "" };
        this.addressValueObject = {
            zipCode: "",
            street: "",
            number: "",
            complement: "",
            province: "",
            city: "",
            state: { id: "", content: "" },
        };
        this.contact = {
            name: "",
            departament: "",
            mobile: "",
            email: "",
            birthDate: "",
        }
        this.creditLimit = 0;
        this.patrimonyLimit = 0;
        this.observation = "";
        this.dayToBilling = 0;
        this.status = 1;
    }
    update(data) {
        this.id = data.id;
        if (data.categoryCustomer)
            this.categoryCustomer = { id: data.categoryCustomer.id, content: data.categoryCustomer.name };

        if (data.originCustomer)
            this.originCustomer = { id: data.originCustomer.id, content: data.originCustomer.name };

        if (data.isCustomer)
            this.options.push("isCustomer");
        if (data.isSupplier)
            this.options.push("isSupplier");

        this.personRequest = data.person;
        this.contactRequest = data.contact;
        this.creditLimit = data.creditLimit;
        this.patrimonyLimit = data.patrimonyLimit;
        this.observation = data.observation;
        this.dayToBilling = data.dayToBilling;
        this.status = data.status;
    }
}