import { render, staticRenderFns } from "./CollaboratorCreate.vue?vue&type=template&id=6dcd0fae&scoped=true&"
import script from "./CollaboratorCreate.vue?vue&type=script&lang=js&"
export * from "./CollaboratorCreate.vue?vue&type=script&lang=js&"
import style0 from "./CollaboratorCreate.vue?vue&type=style&index=0&id=6dcd0fae&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6dcd0fae",
  null
  
)

export default component.exports