<template>
    <div>
        <div v-if="loading">
            <br>
            <span>Aguarde, estamos atualizando os boletos, isso pode levar alguns segundos...</span>
            <Loading type="line" :center="false" />
        </div>
        <div v-else>
            <b-row v-if="listBillet.length != 0">
                <b-col sm="3">
                    <DateTime title="Vencimento" field="dueDate" format="DD/MM/YYYY" type="date" placeholder
                        :required="true" v-model="dueDate" />
                </b-col>
            </b-row>
            <Molded>
                <CheckBillet action="update" :selected="selected" v-model="listBillet" />
            </Molded>
            <div class="btn-generate text-center" v-if="listBillet.length != 0">
                <Button _key="btnUpdateBillet" type="success" title="Alterar Boleto" size="medium" :disabled="!dueDate"
                    :clicked="updateBillet" />
            </div>
        </div>

    </div>
</template>
<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import CheckBillet from './CheckBillet.vue'

import { mapActions, mapMutations } from "vuex";

export default {

    name: "UpdateBillet",
    props: {
        selected: Array
    },
    components: { Molded, Button, CheckBillet, DateTime, Alert, Loading },
    data() {
        return {
            urlCheck: "/api/v1/billing/billet/update-billet",
            dueDate: "",
            listBillet: [],
            loading: false
        }
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "removeLoading"]),
        ...mapActions("generic", ["postApi"]),
        updateBillet() {
            this.loading = true;
            let obj = {
                dueDate: this.dueDate,
                listBillet: this.listBillet
            };
            let params = { url: this.urlCheck, obj: obj, notNotifyToast: true };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.addEvent({ name: "updatedBillet" });
                }
                this.loading = false;
                this.removeLoading(["btnUpdateBillet"]);
            });
        },
    }

}
</script>
<style scoped>
.btn-generate {
    margin-top: 10px;
}
</style>