<template>
    <div>
        <div v-if="!success">
            <div class="div-btn-back" v-if="showButtonBack">
                <Button _key="back" title="Voltar" type="info" classIcon="fa-solid fa-arrow-left" size="small"
                    :clicked="executeBack" />
            </div>
            <div>
                <InputText title="Assunto" field="email" :maxLength="100" :required="true" v-model="subject" />
                <b-row>
                    <b-col sm="10">
                        <InputTag title="Para" placeholder="Digite um e-mail e pressione Enter" :required="true"
                            :initialValue="tos" v-model="tos" />
                    </b-col>
                    <b-col sm="2">
                        <div class="btn-contact">
                            <Button _key="btnImportAllRecipient" type="success" title="Todos"
                                classIcon="fa-solid fa-users-medical" size="small" :clicked="importAllRecipient" />
                        </div>
                    </b-col>
                </b-row>
            </div>
            <div>
                <span class="title">Mensagem</span>
                <ScrollBar :minHeight="250" :maxHeight="250">
                    <EditorHtml field="observationServiceOrder" :required="false" :maxLength="3000" v-model="message" />
                    <br>
                </ScrollBar>
            </div>
            <div>
                <span class="title"> Anexo: </span>
                <span v-if="documents.length > 0" class="title-linked title"><i
                        class="fa-solid fa-file-invoice"></i></span>
                <span v-if="listBillet.length > 0" class="title-linked title"> <i
                        class="fa-solid fa-barcode"></i></span>
            </div>
            <div class="text-center div-btn-send">
                <Button _key="btnSendMail" title="Enviar" type="info" classIcon="fa-solid fa-paper-plane-top"
                    size="medium" :disabled="tos.length == 0 || !subject" :clicked="sendMail" />
            </div>
        </div>
        <div v-else>
            <div class="text-center">
                <div class="msg-success">Envio em processamento, para consultar o status clique no ícone
                    <span class="icon-messager"> <i class="fa-solid fa-message-lines"></i></span> na barra superior!
                </div>
                <div class="icon-success">
                    <i class="fa-solid fa-timer"></i>
                </div>
                <Timer :title="'Fechando em'" :initialTimer="5" :endTime="emailSent" />
            </div>
        </div>
    </div>
</template>
<script>

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputTag from "@nixweb/nixloc-ui/src/component/forms/InputTag";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";
import Timer from "@nixweb/nixloc-ui/src/component/shared/Timer";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";

import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
    name: "SendMail",
    props: {
        module: String,
        documents: {
            type: Array,
            default: []
        },
        rentId: String,
        periodRentId: String,
        movimentId: String,
        invoiceId: String,
        payableReceivableId: String,
        orderServiceId: String,
        listBillet: {
            type: Array,
            default: []
        },
        showButtonBack: {
            type: Boolean,
            default: true
        },
        back: Function,
    },
    components: {
        Button, InputText, InputTag, EditorHtml, Timer, RadioGroup, ScrollBar
    },
    data() {
        return {
            urlSend: "/api/v1/messager/mail/send-mail",
            urlGetRecipient: "/api/v1/messager/mail/get-recipient",
            subject: "Olá, segue documentos...",
            recipient: "",
            tos: [],
            message: "",
            tosSeparate: false,
            success: false,
        }
    },
    mounted() {
        this.getRecipient(false);
    },
    computed: {
        ...mapState("user", ["userLogged"]),
        ...mapGetters("generic", ["listIdToString"]),
        signature() {
            return `${this.recipient}<p>Estou enviando este e-mail para compartilhar o(s) documento(s) com você.</p><p>Fique à vontade para revisá-lo e entrar em contato.</p><p>Agradeço sua atenção e fico à disposição para qualquer esclarecimento necessário.</p><p>Atenciosamente</p>${this.userLogged.user.name}`;
        }
    },
    methods: {
        ...mapActions("generic", ["postApi"]),
        ...mapMutations("generic", ["hideModal", "addEvent", "removeLoading"]),
        importAllRecipient() {
            this.getRecipient(true);
        },
        getRecipient(importAllEmails) {
            let params = {
                url: this.urlGetRecipient, obj: {
                    importAllEmails: importAllEmails,
                    listBillet: this.listBillet,
                    printRequest: {
                        rentId: this.rentId,
                        invoiceId: this.invoiceId,
                        payableReceivableId: this.payableReceivableId,
                        orderServiceId: this.orderServiceId
                    }
                },
                notNotifyToast: true
            };
            this.postApi(params).then((response) => {
                var name = response.content.length > 1 ? "Prezados(a), " : "Prezado(a) ";
                response.content.forEach(contact => {
                    this.recipient = `${name}${contact.name}`;
                    this.tos.push(contact.email);
                });
                this.recipient = response.content.length > 1 ? "Prezados(a), " : this.recipient + ",";

                this.message = this.signature;
                this.removeLoading(["btnImportAllRecipient"]);
            });
        },
        sendMail() {
            let params = {
                url: this.urlSend, obj: {
                    replyName: this.userLogged.user.name,
                    replyTo: this.userLogged.user.email,
                    tosSeparate: true,
                    subject: this.subject,
                    tos: this.tos,
                    htmlContent: this.message,
                    module: this.module,
                    listBillet: this.listBillet,
                    userId: this.userLogged.user.id,
                    printRequest: {
                        documentTitle: "Documento",
                        documentIds: this.listIdToString(this.documents),
                        rentId: this.rentId,
                        periodRentId: this.periodRentId,
                        movimentId: this.movimentId,
                        invoiceId: this.invoiceId,
                        payableReceivableId: this.payableReceivableId,
                        orderServiceId: this.orderServiceId,
                        userName: this.userLogged.user.userName,
                        password: this.userLogged.user.password,
                        companyId: this.userLogged.companyId
                    }
                },
                notNotifyToast: true
            };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.success = true;
                }
                this.removeLoading(["btnSendMail"]);
            });
        },
        cleanForm() {
            this.subject = "";
            this.message = "";
            this.tos = [];
            this.success = false;
        },
        emailSent() {
            this.addEvent({ name: "emailSent" });
            this.hideModal();
            this.cleanForm();
            this.removeLoading(["btnSendMail"]);
        },
        executeBack() {
            if (this.back) this.back();
        },
    }

}


</script>
<style scoped>
.div-btn-back {
    margin-bottom: 10px;
}

.div-btn-send {
    margin-top: 10px;
}


.attachment {
    font-size: 16px;
    margin-left: 5px;
}

.msg-success {
    font-size: 15px;
}

.icon-success {
    font-size: 30px;
    color: darkorange;
    margin-top: 10px;
    margin-bottom: 15px;
}

.icon-messager {
    font-size: 18px;
    padding: 8px;
}

.div-tos-separate {
    margin-top: 12px;
}

.btn-contact {
    margin-top: 30px;
    margin-right: 20px;
}

.title-linked {
    margin-right: 8px;
    font-size: 16px;
}
</style>