<template>
    <div>
        <Loading :center="false" v-if="loading" />
        <ScrollBar v-else :minHeight="200" :maxHeight="300">
            <div v-for="billet in content">
                <div class="div-billet">
                    <b-row>
                        <b-col sm="8">
                            <span class="icon-error" v-if="billet.error">
                                <i class="fa-solid fa-triangle-exclamation"></i>
                                Inválido -
                            </span>
                            <span v-else>
                                <i class="icon-ok fa-solid fa-circle-plus" v-if="action == 'create'"></i>
                                <i class="icon-update fa-solid fa-calendar-lines-pen" v-if="action == 'update'"></i>
                                <i class="icon-error fa-solid fa-ban" v-if="action == 'cancel'"></i>
                                <i class="icon-pdf fa-solid fa-file-pdf" v-if="action == 'pdf'"></i>
                            </span>
                            <span class="title">
                                {{ billet.recipientName }} -
                                {{ billet.description }}
                            </span>
                        </b-col>
                        <b-col sm="2">
                            <span class="title"> {{ billet.dueDate }}</span>
                        </b-col>
                        <b-col sm="2">
                            <span class="title"> {{ billet.value | currency }}</span>
                        </b-col>
                    </b-row>
                    <div class="icon-error">
                        {{ billet.error }}
                    </div>
                </div>
            </div>
            <div v-if="content.length == 0">
                <span class="title"> Nenhum registro encontrado!</span>
            </div>
        </ScrollBar>
    </div>
</template>

<script>

import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import { mapActions } from "vuex";

export default {
    name: "CheckBillet",
    props: {
        selected: Array,
        value: Array,
        action: String,
    },
    components: {
        ScrollBar, Loading
    },
    data() {
        return {
            urlCheck: "/api/v1/billing/billet/check-billet",
            content: {},
            loading: true
        }
    },
    mounted() {
        this.checkBillet();
    },
    methods: {
        ...mapActions("generic", ["postApi"]),
        checkBillet() {
            let obj = {
                listIds: this.selected,
                action: this.action
            };
            let params = { url: this.urlCheck, obj: obj, notNotifyToast: true };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.content = response.content;
                }
                this.loading = false;
            });
        },
    },
    watch: {
        content: {
            handler(content) {
                this.$emit("input", content);
            },
            deep: true,
        },
    }
}

</script>
<style scoped>
.div-billet {
    border: 1px solid #e8eaed;
    background-color: #FAFAFC;
    border-radius: 8px !important;
    padding: 7px;
    margin-right: 4px;
    margin-bottom: 10px;
}

.icon-ok {
    color: #007BFF;
}

.icon-update {
    color: darkgray;
}

.icon-error {
    color: red;
}

.icon-pdf {
    color: #0011FF;
}
</style>