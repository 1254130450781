<template>
  <div>
    <b-row>
      <b-col sm="12">
        <InputText title="Nome" field="name" :formName="formName" :required="true" :maxLength="40"
          v-model="originCustomer.name" :markFormDirty="false" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button _key="saveOriginCustomer" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-save" size="medium" :clicked="saveOriginCustomer" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import OriginCustomer from "@/components/modules/crm/category-customer/OriginCustomer.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "OriginCustomerCreateUpdate",
  props: {
    registerInSelect: Boolean,
    value: Object,
  },
  components: { Button, InputText },
  data() {
    return {
      formName: "originCustomerCreateUpdate",
      originCustomer: new OriginCustomer(),
      urlCreate: "/api/v1/crm/origin-customer/create",
      urlUpdate: "/api/v1/crm/origin-customer/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", ["removeLoading", "hideModal", "hideVodal"]),
    ...mapMutations("validation", ["resetValidation"]),
    saveOriginCustomer() {
      if (this.originCustomer.id) {
        let params = { url: this.urlUpdate, obj: this.originCustomer };
        this.putApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          this.removeLoading(["saveOriginCustomer"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.originCustomer };
        this.postApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          if (response.success && this.registerInSelect) {
            this.$emit("input", {
              id: response.content.id,
              content: response.content.name,
            });
            this.hideVodal(this.formName);
          }
          this.removeLoading(["saveOriginCustomer"]);
        });
      }
    },
    update(data) {
      this.originCustomer = new OriginCustomer();
      this.originCustomer.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "originCustomerCreate") {
          this.resetValidation(this.formName);
          this.originCustomer = new OriginCustomer();
        }
        if (event.name == "originCustomerUpdate") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
