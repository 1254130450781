<template>
    <div class="div-link">
        <div class="div-btn-back" v-if="showButtonBack">
            <Button _key="back" title="Voltar" type="info" classIcon="fa-solid fa-arrow-left" size="small"
                :clicked="executeBack" />
        </div>
        <br v-if="!link">
        <div v-if="loading">
            <span class="title">Aguarde, estamos gerando o link, isso pode levar alguns segundos...</span>
            <Loading type="line" :center="false" />
        </div>
        <div v-if="link">
            <InputText v-model="link" />
            <div @click="copied = link" class="div-clipboard text-center" v-clipboard="link">
                <span class="title" v-if="!copied"> <i class="fa-solid fa-link"></i> Copiar</span>
                <span class="copied title" v-else><i class="fa-solid fa-link"></i> Copiado</span>
            </div>
        </div>
    </div>
</template>
<script>
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import { mapActions, mapMutations, mapState, mapGetters } from "vuex";

export default {
    name: "Link",
    props: {
        module: String,
        documents: {
            type: Array,
            default: []
        },
        rentId: String,
        periodRentId: String,
        movimentId: String,
        invoiceId: String,
        payableReceivableId: String,
        listBillet: {
            type: Array,
            default: []
        },
        showButtonBack: {
            type: Boolean,
            default: true
        },
        back: Function,
    },
    components: {
        Button,
        InputText,
        Loading
    },
    data() {
        return {
            urlGetLink: "/api/v1/messager/mail/get-link",
            link: "",
            copied: "",
            loading: true,
        };
    },
    mounted() {
        this.getLink();
    },
    computed: {
        ...mapState("user", ["userLogged"]),
        ...mapGetters("generic", ["listIdToString"]),
    },
    methods: {
        ...mapActions("generic", ["postApi"]),
        ...mapMutations("generic", ["removeLoading"]),
        getLink() {
            let params = {
                url: this.urlGetLink, obj: {
                    module: this.module,
                    listBillet: this.listBillet,
                    printRequest: {
                        documentTitle: "Documento",
                        documentIds: this.listIdToString(this.documents),
                        rentId: this.rentId,
                        periodRentId: this.periodRentId,
                        movimentId: this.movimentId,
                        invoiceId: this.invoiceId,
                        payableReceivableId: this.payableReceivableId,
                        userName: this.userLogged.user.userName,
                        password: this.userLogged.user.password,
                        companyId: this.userLogged.companyId
                    }
                },
                notNotifyToast: true
            };
            this.postApi(params).then((response) => {
                this.link = response.content;
                this.loading = false;
                this.removeLoading(["btnGenerateLink"]);
            });
        },
        executeBack() {
            if (this.back) this.back();
        },
    },
};
</script>
<style scoped>
.div-link {
    height: 150px;
}

.div-clipboard {
    cursor: pointer;
}

.copied {
    color: darkblue;
}
</style>