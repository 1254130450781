export default class TypeProduct {
    constructor() {
        this.id = "";
        this.name = "";
        this.isBankSlip = false;
        this.module = ["Rental"];
    }
    update(data) {
        this.id = data.id;
        this.name = data.name;
        this.isBankSlip = data.isBankSlip;
        this.module = data.module;
    }
}