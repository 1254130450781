<template>
    <div>
        <div v-if="loading">
            <br>
            <span>Aguarde, estamos gerando os boletos, isso pode levar alguns segundos...</span>
            <Loading type="line" :center="false" />
        </div>
        <div v-else>
            <div class="div-select" v-if="listBillet.length != 0">
                <b-row>
                    <b-col sm="12">
                        <Select title="Selecione a Conta Boleto" field="bankAccount" :required="true"
                            :showNewRegister="false" url="api/v1/billing/billet-config/select-all" v-model="bankAccount" />
                    </b-col>
                </b-row>
            </div>
            <Molded>
                <CheckBillet action="create" :selected="selected" v-model="listBillet" />
            </Molded>
            <div class="btn-generate text-center" v-if="listBillet.length != 0">
                <Button _key="btnGenerateBillet" type="primary" title="Emitir Boleto" size="medium"
                    :disabled="!bankAccount.id" :clicked="generateBillet" />
            </div>
        </div>
    </div>
</template>
<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import CheckBillet from './CheckBillet.vue'

import { mapActions, mapMutations } from "vuex";

export default {

    name: "CreateBillet",
    props: {
        selected: Array
    },
    components: { Molded, Button, CheckBillet, Select, Loading },
    data() {
        return {
            urlCheck: "/api/v1/billing/billet/generate-billet",
            bankAccount: {},
            listBillet: [],
            loading: false
        }
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "removeLoading"]),
        ...mapActions("generic", ["postApi"]),
        generateBillet() {
            this.loading = true;
            let obj = {
                bankAccount: this.bankAccount,
                listBillet: this.listBillet
            };
            let params = { url: this.urlCheck, obj: obj, notNotifyToast: true };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.addEvent({ name: "generatedBillet" });
                }
                this.loading = false;
                this.removeLoading(["btnGenerateBillet"]);
            });
        },
    }

}
</script>
<style scoped>
.btn-generate {
    margin-top: 10px;
}

.div-select{
    margin-bottom: 12px;
}

</style>