<template>
    <div>
        <div v-if="!success">
            <div class="div-btn-back" v-if="showButtonBack">
                <Button _key="back" title="Voltar" type="info" classIcon="fa-solid fa-arrow-left" size="small"
                    :clicked="executeBack" />
            </div>
            <b-row>
                <b-col sm="6">
                    <InputText title="Nome" field="email" :maxLength="100" :required="true" v-model="nameDocument" />
                </b-col>
                <b-col sm="6">
                    <InputText title="Mensagem" field="observationServiceOrder" :required="false" :maxLength="500"
                        v-model="message" />
                </b-col>
            </b-row>
            <span class="title">Informe os signatários</span>
            <div class="div-molded-signature">
                <Molded>
                    <b-row>
                        <b-col sm="4">
                            <InputText title="Nome" :required="false" :maxLength="50" v-model="signer.name" />
                        </b-col>
                        <b-col sm="4">
                            <InputText title="E-mail" :required="false" :maxLength="100" v-model="signer.email" />
                        </b-col>
                        <b-col sm="3">
                            <InputText title="Celular" :required="false" :maxLength="50" v-model="signer.phoneNumber" />
                        </b-col>
                        <b-col sm="1">
                            <div class="div-btn-add">
                                <Button type="primary" :disabled="isDisabled" classIcon="fa-regular fa-plus-large"
                                    size="small" :clicked="addSigner" />
                            </div>
                        </b-col>
                    </b-row>
                </Molded>
            </div>
            <div class="text-center" v-if="signers.length > 0"><span class="title">Signatários</span></div>
            <ScrollBar :minHeight="150" :maxHeight="250" v-if="signers.length > 0">
                <div v-for="signer in signers">
                    <div class="div-signer">
                        <b-row>
                            <b-col sm="10">
                                <span v-if="signer.name">
                                    {{ signer.name }}
                                </span>
                                <span v-if="signer.email">
                                    - {{ signer.email }}
                                </span>
                                <span v-if="signer.phoneNumber">
                                    - {{ signer.phoneNumber }}
                                </span>
                            </b-col>
                            <b-col sm="2">

                                <div class="div-delete text-right" @click="deleteSigner(signer)">
                                    <i class="fa-sharp fa-solid fa-circle-xmark"></i>
                                </div>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </ScrollBar>
            <div class="text-center div-btn-signature">
                <Button _key="btnSendSignature" title="Enviar" type="info" classIcon="fa-solid fa-paper-plane-top"
                    size="medium" :disabled="signers.length == 0 || !nameDocument" :clicked="sendSignature" />
            </div>
        </div>
        <div v-else>
            <div class="text-center">
                <div class="msg-success">Envio em processamento, para consultar o status clique no ícone
                    <span class="icon-messager"> <i class="fa-solid fa-message-lines"></i></span> na barra superior!
                </div>
                <div class="icon-success">
                    <i class="fa-solid fa-timer"></i>
                </div>
                <Timer :title="'Fechando em'" :initialTimer="5" :endTime="emailSent" />
            </div>
        </div>
    </div>
</template>
<script>

import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputTag from "@nixweb/nixloc-ui/src/component/forms/InputTag";
import EditorHtml from "@nixweb/nixloc-ui/src/component/forms/EditorHtml";
import Timer from "@nixweb/nixloc-ui/src/component/shared/Timer";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";

import { mapState, mapGetters, mapActions, mapMutations } from "vuex";

export default {
    name: "SendSignature",
    props: {
        module: String,
        documents: {
            type: Array,
            default: []
        },
        rentId: String,
        periodRentId: String,
        movimentId: String,
        invoiceId: String,
        payableReceivableId: String,
        showButtonBack: {
            type: Boolean,
            default: true
        },
        single: Boolean,
        singleUrlDocument: String,
        back: Function,
    },
    components: {
        Button, InputText, InputTag, EditorHtml, Timer, RadioGroup, ScrollBar, Molded
    },
    data() {
        return {
            urlSend: "/api/v1/signature/send",
            urlGetRecipient: "/api/v1/messager/mail/get-recipient",
            nameDocument: "Segue documentos para assi...",
            message: "",
            signer: {
                name: "",
                email: "",
                phoneNumber: ""
            },
            signers: [],

            success: false,
        }
    },
    mounted() {
        this.getRecipient(false);
    },
    computed: {
        ...mapState("user", ["userLogged"]),
        ...mapGetters("generic", ["listIdToString"]),
        isDisabled() {
            if (!this.signer.name || !this.signer.email) return true;
            return false;
        }
    },
    methods: {
        ...mapActions("generic", ["postApi"]),
        ...mapMutations("generic", ["hideModal", "addEvent", "removeLoading"]),
        importAllRecipient() {
            this.getRecipient(true);
        },
        getRecipient(importAllEmails) {
            let params = {
                url: this.urlGetRecipient, obj: {
                    importAllEmails: importAllEmails,
                    printRequest: {
                        rentId: this.rentId,
                        invoiceId: this.invoiceId,
                        payableReceivableId: this.payableReceivableId
                    }
                },
                notNotifyToast: true
            };
            this.postApi(params).then((response) => {
                response.content.forEach(contact => {
                    this.signers.push({ name: contact.name, email: contact.email });
                });
                this.removeLoading(["btnSendSignature"]);
            });
        },
        sendSignature() {
            let params = {
                url: this.urlSend, obj: {
                    name: this.nameDocument,
                    single: this.single,
                    singleUrlDocument: this.singleUrlDocument,
                    module: this.module,
                    customMessage: this.message,
                    signerRequest: this.signers,
                    genericId: this.rentId || this.invoiceId,
                    printRequest: {
                        documentTitle: "Documento",
                        documentIds: this.listIdToString(this.documents),
                        rentId: this.rentId,
                        periodRentId: this.periodRentId,
                        movimentId: this.movimentId,
                        invoiceId: this.invoiceId,
                        payableReceivableId: this.payableReceivableId,
                        userName: this.userLogged.user.userName,
                        password: this.userLogged.user.password,
                        companyId: this.userLogged.companyId
                    }
                },
                notNotifyToast: true
            };
            let self = this;
            setTimeout(function () {
                self.success = true;
                self.removeLoading(["btnImportAllRecipient"]);
            }, 1500);
            this.postApi(params).then();
        },
        addSigner() {
            this.signers.push(this.signer);
            this.signer = {
                name: "",
                email: "",
                phoneNumber: ""
            };
        },
        deleteSigner(item) {
            const updatedSigners = this.signers.filter(signer => {
                return signer.name !== item.name || signer.email !== item.email || signer.phoneNumber !== item.phoneNumber;
            });
            this.signers = updatedSigners;
        },
        cleanForm() {
            this.nameDocument = "";
            this.message = "";
            this.signers = [];
            this.success = false;
        },
        emailSent() {
            this.addEvent({ name: "signatureSent" });
            this.hideModal();
            this.cleanForm();
            this.removeLoading(["btnSendSignature"]);
        },
        executeBack() {
            this.removeLoading(["back"]);
            if (this.back) this.back();
        },
    }

}

</script>
<style scoped>
.div-btn-back {
    margin-bottom: 10px;
}

.div-btn-signature {
    margin-top: 10px;
}

.div-signer {
    margin-left: 50px;
    margin-right: 50px;
    height: 40px;
    border: 1px solid #E5E4E8;
    border-radius: 10px;
    background-color: #FAFAFC;
    padding-left: 10px;
    padding-top: 5px;
    margin-bottom: 10px;
}

.div-molded-signature {
    margin-bottom: 10px;
}

.div-btn-add {
    margin-top: 36px;
}

.div-delete {
    margin-right: 10px;
    color: red;
    cursor: pointer;
}

.msg-success {
    font-size: 15px;
}

.icon-success {
    font-size: 30px;
    color: darkorange;
    margin-top: 10px;
    margin-bottom: 15px;
}

.icon-messager {
    font-size: 18px;
    padding: 8px;
}
</style>