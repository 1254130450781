<template>
    <div>
        <div v-if="loading">
            <span>Aguarde, estamos gerando o PDF, isso pode levar alguns segundos...</span>
            <Loading type="line" :center="false" />
        </div>
        <div v-else>
            <div v-show="!sendEmail && !link">
                <Molded>
                    <CheckBillet action="pdf" :selected="selected" v-model="listBillet" />
                </Molded>

                <div class="btn-generate text-center" v-if="listBillet.length != 0">

                    <Button _key="btnPdfBillet" type="info" title="Baixar PDF" classIcon="fa-solid fa-download" size="small"
                        :clicked="gerPdf" />

                    <Button _key="btnSendMail" v-if="showButtonEmail" type="info" title="Enviar por E-mail"
                        classIcon="fa-solid fa-envelope" size="small" :clicked="executeSendMail" />

                    <Button _key="btnLink" v-if="showButtonLink" type="info" title="Gerar Link" classIcon="fa-solid fa-link"
                        size="small" :clicked="executeLink" />

                </div>
            </div>
            <Link v-if="link" :listBillet="listBillet" :showButtonBack="false" />
            <SendMail v-if="sendEmail" :listBillet="listBillet" :showButtonBack="false" />
        </div>

    </div>
</template>
<script>


import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import CheckBillet from './CheckBillet.vue'
import SendMail from '../../adm/document/SendMail.vue'
import Link from '../../adm/document/Link.vue'

import { mapActions, mapMutations, mapGetters } from "vuex";

export default {
    name: "PDFBillet",
    props: {
        selected: Array,
        showButtonEmail: {
            type: Boolean,
            default: true
        },
        showButtonLink: {
            type: Boolean,
            default: true
        }
    },
    components: { Alert, Molded, Button, Loading, CheckBillet, SendMail, Link },
    data() {
        return {
            urlCheck: "/api/v1/billing/billet/pdf-billet",
            listBillet: [],
            loading: false,
            sendEmail: false,
            link: false,
        }
    },
    computed: {
        ...mapGetters("generic", ["event"]),
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "removeLoading"]),
        ...mapActions("generic", ["getFileApiWithParameterBody"]),
        gerPdf() {
            this.loading = true;
            let obj = {
                listBillet: this.listBillet,
                fileName: `boleto`,
                fileType: "pdf"
            };
            let params = { url: this.urlCheck, obj: obj, notNotifyToast: true };
            this.getFileApiWithParameterBody(params).then((response) => {
                this.addEvent({ name: "generatedPdfBillet" });
                this.loading = false;
                this.removeLoading(["btnPdfBillet"]);
            });
        },
        executeSendMail() {
            this.sendEmail = true;
            this.removeLoading(["btnSendMail"]);
        },
        executeLink() {
            this.link = true;
            this.removeLoading(["btnLink"]);
        }
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "back" || event.name == "emailSent") {
                    this.sendEmail = false;
                    this.link = false;
                }
            },
            deep: true,
        },
    }

}
</script>
<style scoped>
.btn-generate {
    margin-top: 10px;
}
</style>