<template>
  <div>
    <b-row>
      <b-col sm="12">
        <InputText title="Nome" field="name" :formName="formName" :required="true" :maxLength="50"
          v-model="typePayment.name" :markFormDirty="false" />
      </b-col>
      <b-col sm="6" v-if="hasRule('Billet')">
        <RadioGroup title="Emite Boleto?" field="type" :formName="formName" :options="[
          { text: 'Sim', value: true },
          { text: 'Não', value: false },
        ]" v-model="typePayment.isBankSlip" />
      </b-col>
      <b-col sm="6" v-if="options.length > 0 && hasRule('PDV')">
        <CheckboxGroup title="Módulo" field="module" :markFormDirty="false" :options="options"
          :initialValue="typePayment.module" v-model="typePayment.module" />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="12">
        <div class="text-right">
          <Button _key="btnSavePaymentType" type="success" title="Salvar" :disabled="!isFormValid(formName)"
            classIcon="fas fa-save" size="medium" :clicked="saveTypePayment" />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";

import TypePayment from "@/components/modules/finance/type-payment/TypePayment.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "TypePaymentCreateUpdate",
  props: {
    registerInSelect: Boolean,
    value: Object,
  },
  components: { InputText, RadioGroup, Button, CheckboxGroup },
  data() {
    return {
      formName: "typePaymentCreateUpdate",
      typePayment: new TypePayment(),
      urlCreate: "/api/v1/finance/type-payment/create",
      urlUpdate: "/api/v1/finance/type-payment/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
    ...mapGetters("user", ["hasRule"]),
    options() {
      let final = [];

      let options = [
        { text: "PDV", value: "PDV" },
        { text: "Locação", value: "Rental" },
      ];

      options.forEach((item) => {
        if (item.value == "Rental" && this.hasRule("Rental")) final.push(item);
        if (item.value == "PDV" && this.hasRule("PDV")) final.push(item);
      });

      return final;
    },
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", [
      "removeLoading",
      "removeEvent",
      "hideModal",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation"]),
    saveTypePayment() {
      if (this.typePayment.id) {
        let params = { url: this.urlUpdate, obj: this.typePayment };
        this.putApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          this.removeLoading(["btnSavePaymentType"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.typePayment };
        this.postApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          if (response.success && this.registerInSelect) {
            this.$emit("input", {
              id: response.content.id,
              content: response.content.name,
            });
            this.hideVodal(this.formName);;
          }
          this.removeLoading(["btnSavePaymentType"]);
        });
      }
    },
    update(data) {
      this.typePayment = new TypePayment();
      this.typePayment.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "paymentTypeCreate") {
          this.resetValidation(this.formName);
          this.typePayment = new TypePayment();
        }
        if (event.name == "typePaymentUpdate") this.update(event.data);
      },
      deep: true,
    },
  },
};
</script>
