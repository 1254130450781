<template>
  <div class="margin">
    <b-tabs>
      <b-tab title="Principal">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <CheckboxGroup field="options" :formName="formName" :initialValue="collaborator.options" :options="[
              { text: 'Consultor', value: 'isConsultant' },
              { text: 'Freelancer', value: 'isFreelance' },
              { text: 'Funcionário', value: 'isEmployee' },
            ]" v-model="collaborator.options" />
          </b-col>
        </b-row>
        <Person :formName="formName" :showTypePerson="false" v-model="collaborator.personRequest" />
        <b-row v-if="showSelectUser">
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <Select title="Usuário Vinculado" field="user" :formName="formName" :required="showSelectUser"
              url="/api/v1/adm/user/select-all" v-model="collaborator.user">
            </Select>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab title="Endereço">
        <AddressValueObject :formName="formName" :required="false" :markFormDirty="false"
          v-model="collaborator.addressValueObject" />
      </b-tab>
      <b-tab title="Telefone, Site e E-mail">
        <Contact :formName="formName" v-model="collaborator.contactRequest" />
      </b-tab>
      <b-tab title="Observações">
        <TextArea title="Observações" field="observation" :required="false" :maxLength="800"
          v-model="collaborator.observation" />
      </b-tab>
    </b-tabs>
    <br />
    <Button _key="cancelCollaborator" title="Cancelar" type="danger" size="small" :clicked="cancel" />
    <Button _key="saveCollaborator" type="success" title="Salvar" :disabled="!isFormValid(formName)"
      classIcon="fas fa-save" size="medium" :clicked="create" />
  </div>
</template>

<script>
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded.vue";
import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";
import TextArea from "@nixweb/nixloc-ui/src/component/forms/TextArea";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import AddressValueObject from "@nixweb/nixloc-ui/src/component/value-objects/Address.vue";

import Contact from "@nixweb/nixloc-ui/src/component/value-objects/Contact.vue";
import Person from "@nixweb/nixloc-ui/src/component/value-objects/Person.vue";

import Collaborator from "@/components/modules/human-resources/collaborator/Collaborator.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "CollaboratorCreate",
  props: {
    registerInSelect: Boolean,
    value: Object,
  },
  components: {
    InputText,
    Select,
    Button,
    RadioGroup,
    TextArea,
    CheckboxGroup,
    Person,
    Contact,
    Molded,
    AddressValueObject,
  },
  data() {
    return {
      formName: "collaboratorCreate",
      collaborator: new Collaborator(),
      urlCreate: "/api/v1/human-resources/collaborator/create",
      tabIndex: 0,
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "isLoading"]),
    showSelectUser() {
      return this.collaborator.options.includes("isConsultant");
    },
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi", "getApi"]),
    ...mapMutations("generic", ["removeLoading", "hideVodal", "hideSideBar"]),
    ...mapMutations("validation", ["resetValidation", "removeFormDirty"]),
    create() {
      this.collaborator.addressValueObject.state =
        this.collaborator.addressValueObject.state.content;

      console.log(this.collaborator);

      let params = { url: this.urlCreate, obj: this.collaborator };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.hideVodal("customerSupplierOrCollaboratorCreate");
          this.$emit("input", {
            id: response.content.id,
            content: response.content.person.companyName,
          });
        }
        this.removeLoading(["saveCollaborator"]);
      });
    },
    cancel() {
      this.hideVodal("customerSupplierOrCollaboratorCreate");
      this.removeLoading(["cancelCollaborator"]);
    },
  },
};
</script>

<style scoped>
.margin {
  margin-right: 10px;
}
</style>
