<template>
    <div>
        <div v-if="loading">
            <br>
            <span>Aguarde, estamos cancelando os boletos, isso pode levar alguns segundos...</span>
            <Loading type="line" :center="false" />
        </div>
        <div v-else>
            <Molded>
                <CheckBillet action="cancel" :selected="selected" v-model="listBillet" />
            </Molded>
            <div class="btn-generate text-center" v-if="listBillet.length != 0">
                <Button _key="btnCancelBillet" type="danger" title="Cancelar Boleto" size="medium"
                    :clicked="cancelBillet" />
            </div>
        </div>

    </div>
</template>
<script>
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";
import Molded from "@nixweb/nixloc-ui/src/component/layout/Molded";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import Loading from "@nixweb/nixloc-ui/src/component/shared/Loading.vue";

import CheckBillet from './CheckBillet.vue'

import { mapActions, mapMutations } from "vuex";

export default {
    name: "CancelBillet",
    props: {
        selected: Array
    },
    components: { Alert, Molded, Button, Loading, CheckBillet },
    data() {
        return {
            urlCheck: "/api/v1/billing/billet/cancel-billet",
            listBillet: [],
            loading: false
        }
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "removeLoading"]),
        ...mapActions("generic", ["postApi"]),
        cancelBillet() {
            this.loading = true;
            let obj = {
                listBillet: this.listBillet
            };
            let params = { url: this.urlCheck, obj: obj, notNotifyToast: true };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.addEvent({ name: "canceledBillet" });
                }
                this.loading = false;
                this.removeLoading(["btnCancelBillet"]);
            });
        },
    }

}
</script>
<style scoped>
.btn-generate {
    margin-top: 10px;
}
</style>