<template>
    <div>
        <b-row>
            <b-col sm="5">
                <Select title="Nº Locação" field="type" :formName="formName" :markFormDirty="false" :required="true"
                    url="api/v1/rental/rent/select-all" v-model="relationshipRent.rent" :showNewRegister="false">
                </Select>
            </b-col>
            <b-col sm="4">
                <InputText title="Descrição" field="description" :formName="formName" :markFormDirty="false"
                    :required="false" :maxLength="100" v-model="relationshipRent.description" />
            </b-col>
            <b-col sm="2">
                <InputDecimal title="Valor" field="value" :formName="formName" :required="true" :markFormDirty="false"
                    :allowNegative="false" v-model="relationshipRent.value" />
            </b-col>
            <b-col sm="1">
                <div class="div-btn">
                    <Button v-if="!relationshipRent.id" :disabled="!isFormValid(formName)" _key="btn-add" type="primary"
                        classIcon="fa-regular fa-plus-large" size="small" :clicked="add" />

                    <Button v-else :disabled="!isFormValid(formName)" _key="btn-update" type="success"
                        classIcon="fa-solid fa-floppy-disk" size="small" :clicked="update" />
                </div>
            </b-col>
        </b-row>
        <br>
        <RelationshipRentList :payableReceivableId="payableReceivableId" />
    </div>
</template>


<script>


import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";

import RelationshipRentList from './RelationshipRentList.vue'

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
    name: "RelationshipRent",
    components: {
        Button, InputText, InputDecimal, Select, RelationshipRentList
    },
    props: ["payableReceivableId", "payableReceivableValue"],
    data() {
        return {
            formName: "relationshipRent",
            relationshipRent: {
                id: "",
                payableReceivableId: "",
                rent: { id: "", content: "" },
                description: "",
                value: 0,
                payableReceivableValue: 0,
            },
            urlCreate: "/api/v1/finance/relationship-rent/create",
            urlUpdate: "/api/v1/finance/relationship-rent/update",
        }
    },
    computed: {
        ...mapGetters("validation", ["isFormValid"]),
        ...mapGetters("generic", ["event"]),
    },
    methods: {
        ...mapActions("generic", ["postApi", "putApi"]),
        ...mapMutations("generic", ["addEvent", "removeLoading"]),
        ...mapMutations("validation", ["resetValidation"]),
        add() {
            this.relationshipRent.payableReceivableId = this.payableReceivableId;
            this.relationshipRent.payableReceivableValue = this.payableReceivableValue;
            let params = { url: this.urlCreate, obj: this.relationshipRent };
            this.postApi(params).then((response) => {
                if (response.success) {
                    this.clean();
                    this.addEvent({ name: "relationshipRentAdded" });
                }
                this.removeLoading(["btn-add"]);
            });
        },
        update() {
            this.relationshipRent.payableReceivableId = this.payableReceivableId;
            this.relationshipRent.payableReceivableValue = this.payableReceivableValue;
            let params = { url: this.urlUpdate, obj: this.relationshipRent };
            this.putApi(params).then((response) => {
                if (response.success) {
                    this.clean();
                    this.addEvent({ name: "relationshipRentAdded" });
                }
                this.removeLoading(["btn-update"]);
            });
        },
        clean() {
            this.relationshipRent.id = "";
            this.relationshipRent.rent = { id: " ", content: "" };
            this.relationshipRent.description = "";
            this.relationshipRent.value = 0;
            this.resetValidation(this.formName);
        }
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "updateRelationshipRent") {
                    this.relationshipRent.id = event.data.id;
                    this.relationshipRent.rent = event.data.rent;
                    this.relationshipRent.description = event.data.description;
                    this.relationshipRent.value = event.data.value;
                }
            },
            deep: true,
        },
    },
}

</script>

<style scoped>
.div-btn {
    margin-top: 36px;
}
</style>