<template>
    <div class="main">
        <div class="div-btn">
            <b-row>
                <b-col sm="2">
                    <div class="text-left">
                        <div v-show="selectedOption != 0">
                            <Button _key="back" title="Voltar" type="info" classIcon="fa-solid fa-arrow-left" size="small"
                                eventName="back" />
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div v-show="selectedOption == 0">
            <SelectOption buttonSize="large" buttonClassIcon="fas fa-arrow-circle-right" :width="130" :height="80"
                :options="options" v-model="selectedOption" :showButtonNext="false" />
        </div>
        <CreateBillet v-if="selectedOption == 1" :selected="selected" />
        <UpdateBillet v-if="selectedOption == 2" :selected="selected" />
        <CancelBillet v-if="selectedOption == 3" :selected="selected" />
        <PDFBillet v-if="selectedOption == 4" :selected="selected" />
    </div>
</template>
<script>


import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import SelectOption from "@nixweb/nixloc-ui/src/component/shared/SelectOption.vue";
import Alert from "@nixweb/nixloc-ui/src/component/layout/Alert";

import CreateBillet from './CreateBillet.vue'
import UpdateBillet from './UpdateBillet.vue'
import CancelBillet from './CancelBillet.vue'
import PDFBillet from './PDFBillet.vue'

import { mapMutations, mapGetters } from "vuex";

export default {
    name: "Billet",
    props: {
        origin: String,
        selected: Array,
        value: Number
    },
    components: {
        SelectOption,
        Button, CreateBillet, UpdateBillet, CancelBillet, Alert, PDFBillet
    },
    data() {
        return {
            selectedOption: 0,
            options: [
                {
                    title: "Emitir",
                    titleSize: "15",
                    icon: "fa-solid fa-barcode",
                    iconSize: 20,
                    value: 1,
                },
                {
                    title: "Alterar",
                    titleSize: "15",
                    icon: "fa-solid fa-calendar-lines-pen",
                    iconSize: 20,
                    iconStyle: "color:#BBBBBB;",
                    value: 2,
                },
                {
                    title: "Cancelar",
                    titleSize: "15",
                    icon: "fa-solid fa-ban",
                    iconSize: 20,
                    iconStyle: "color:red;",
                    value: 3,
                },
                {
                    title: "Compartilhar",
                    titleSize: "15",
                    icon: "fa-sharp fa-regular fa-share-nodes",
                    iconSize: 20,
                    iconStyle: "color:blue;",
                    value: 4,
                },

            ],
        }
    },
    computed: {
        ...mapGetters("generic", ["event"]),
    },
    methods: {
        ...mapMutations("generic", ["removeLoading"]),

    },
    watch: {
        selectedOption: {
            handler(selectedOption) {
                this.$emit("input", selectedOption);
            },
            deep: true,
        },
        event: {
            handler(event) {
                if (event.name == "generatedBillet") {
                    this.selectedOption = 4;
                    this.$emit("input", selectedOption);
                }

                if (event.name == "back") {
                    this.selectedOption = 0;
                    this.removeLoading(["back"]);
                }
            },
            deep: true,
        },
    }
}
</script>
<style scoped>
.main {
    min-height: 150px;
}

.div-btn {
    margin-bottom: 15px;
}

.title {
    font-size: 18px;
    margin-bottom: 5px;
}
</style>