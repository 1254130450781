<template>
  <div class="margin">
    <b-tabs>
      <b-tab title="Principal">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="12" xl="12">
            <CheckboxGroup field="options" :formName="formName" :initialValue="customerSupplier.options"
              :markFormDirty="false" :options="[
              { text: 'Cliente', value: 'isCustomer' },
              { text: 'Fornecedor', value: 'isSupplier' },
            ]" v-model="customerSupplier.options" />
          </b-col>
        </b-row>
        <Person :formName="formName" :markFormDirty="false" v-model="customerSupplier.personRequest" />
      </b-tab>
      <b-tab title="Endereço">
        <AddressValueObject :formName="formName" :required="false" :markFormDirty="false"
          v-model="customerSupplier.addressValueObject" />
      </b-tab>
      <b-tab title="Telefone, Site e E-mail">
        <Contact :formName="formName" :markFormDirty="false" v-model="customerSupplier.contactRequest" />
      </b-tab>
      <b-tab title="Contato">
        <ContactCreateUpdate :markFormDirty="false" :showButtonSave="false" :required="false"
          v-model="customerSupplier.contact" />
      </b-tab>
      <b-tab title="Categoria">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="4" xl="4">
            <Select title="Categoria" field="category" :formName="formName" :required="false" :markFormDirty="false"
              url="/api/v1/crm/category-customer/select-all" v-model="customerSupplier.categoryCustomer"
              nameNewRegister="categoryCustomerCreateUpdate" titleNewRegister="Categoria Cliente"
              :widthNewRegister="500" :heightNewRegister="250">
              <CategoryCustomerCreateUpdate :registerInSelect="true" v-model="customerSupplier.categoryCustomer" />
            </Select>
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="4" xl="4">
            <Select title="Origem" field="origin" :formName="formName" :required="false" :markFormDirty="false"
              url="/api/v1/crm/origin-customer/select-all" v-model="customerSupplier.originCustomer"
              nameNewRegister="originCustomerCreateUpdate" titleNewRegister="Origem" :widthNewRegister="500"
              :heightNewRegister="250">
              <OriginCustomerCreateUpdate :registerInSelect="true" v-model="customerSupplier.originCustomer" />
            </Select>
          </b-col>
        </b-row>
      </b-tab>
      <!-- <b-tab title="Limite">
        <b-row>
          <b-col xs="12" sm="12" md="12" lg="2" xl="2">
            <InputDecimal title="Lt de Crédito" field="number" :formName="formName" :markFormDirty="false"
              :required="false" v-model="customerSupplier.creditLimit" />
          </b-col>
          <b-col xs="12" sm="12" md="12" lg="2" xl="2">
            <InputDecimal title="Lt de Patrimônio" field="number" :markFormDirty="false" :formName="formName"
              :required="false" v-model="customerSupplier.patrimonyLimit" />
          </b-col>
        </b-row>
      </b-tab> -->
      <b-tab title="Observações">
        <TextArea title="Observações" field="observation" :required="false" :maxLength="800"
          v-model="customerSupplier.observation" :markFormDirty="false" />
      </b-tab>
    </b-tabs>
    <br />
    <Button _key="cancelCustomer" title="Cancelar" type="danger" size="small" :clicked="cancel" />
    <Button _key="saveCustomer" type="success" title="Salvar" :disabled="!isFormValid(formName)" classIcon="fas fa-save"
      size="medium" :clicked="create" />
  </div>
</template>

<script>
import CheckboxGroup from "@nixweb/nixloc-ui/src/component/forms/CheckboxGroup";
import TextArea from "@nixweb/nixloc-ui/src/component/forms/TextArea";
import RadioGroup from "@nixweb/nixloc-ui/src/component/forms/RadioGroup.vue";
import Select from "@nixweb/nixloc-ui/src/component/forms/Select";
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import InputDecimal from "@nixweb/nixloc-ui/src/component/forms/InputDecimal";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";
import SaveCancel from "@nixweb/nixloc-ui/src/component/shared/SaveCancel";

import AddressValueObject from "@nixweb/nixloc-ui/src/component/value-objects/Address.vue";
import ContactCreateUpdate from "../contact/ContactCreateUpdate.vue";

import OriginCustomerCreateUpdate from "../../crm/category-customer/OriginCustomerCreateUpdate.vue";
import CategoryCustomerCreateUpdate from "../../crm/category-customer/CategoryCustomerCreateUpdate.vue";

import Contact from "@nixweb/nixloc-ui/src/component/value-objects/Contact.vue";
import Person from "@nixweb/nixloc-ui/src/component/value-objects/Person.vue";

import CustomerSupplier from "@/components/modules/shared/customer-supplier/CustomerSupplier.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "CustomerSupplierCreate",
  props: {
    _hideSideBar: {
      type: Boolean,
      default: true,
    },
    value: Object,
  },
  components: {
    InputText,
    InputDecimal,
    Select,
    RadioGroup,
    TextArea,
    CheckboxGroup,
    Person,
    Contact,
    Button,
    SaveCancel,
    CategoryCustomerCreateUpdate,
    OriginCustomerCreateUpdate,
    AddressValueObject,
    ContactCreateUpdate,
  },
  data() {
    return {
      formName: "customerSupplierCreate",
      customerSupplier: new CustomerSupplier(),
      urlCreate: "/api/v1/crm/customer/create",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event", "isLoading"]),
  },
  methods: {
    ...mapActions("generic", ["postApi"]),
    ...mapMutations("generic", ["removeLoading", "hideVodal", "hideSideBar"]),
    create() {
      this.customerSupplier.addressValueObject.state =
        this.customerSupplier.addressValueObject.state.content;

      let params = { url: this.urlCreate, obj: this.customerSupplier };
      this.postApi(params).then((response) => {
        if (response.success) {
          this.hideVodal(this.formName);
          this.hideVodal("customerSupplierOrCollaboratorCreate");

          if (this._hideSideBar) this.hideSideBar();
          this.$emit("input", {
            id: response.content.id,
            content: response.content.person.companyName,
          });
        }
        this.removeLoading(["saveCustomer"]);
      });
    },
    cancel() {
      if (this._hideSideBar) this.hideSideBar();
      this.hideVodal(this.formName);
      this.hideVodal("customerSupplierOrCollaboratorCreate");
      this.removeLoading(["cancelCustomer"]);
    },
  },
};
</script>

<style scoped>
.margin {
  margin-right: 10px;
}
</style>
