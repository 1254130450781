<template>
    <div>

        <div>
            <table class="table table-responsive-xs">
                <thead>
                    <tr>
                        <th class="title-header">Nº Locação</th>
                        <th class="title-header">Descrição</th>
                        <th class="title-header text-center">Valor</th>
                        <th class="text-center"></th>
                    </tr>
                </thead>
                <tbody v-for="item in content.data" :key="item.id">
                    <td class="td-edit" @click="update(item)">
                        <span> {{ item.rent.content }}</span>
                    </td>
                    <td>
                        <span> {{ item.description }}</span>
                    </td>
                    <td class="text-center">
                        <span> {{ item.value | currency }}</span>
                    </td>
                    <td class="text-center">
                        <Button :_key="item.id" type="danger" classIcon="fa-solid fa-trash" size="small"
                            :params="{ id: item.id }" :clicked="remove" />
                    </td>

                </tbody>
                <tbody v-show="content.data == 0">
                    <tr>
                        <td colspan="12">
                            <span>Nenhum registro adicionado!</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>

import ScrollBar from "@nixweb/nixloc-ui/src/component/layout/ScrollBar.vue";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import { mapMutations, mapActions, mapGetters } from "vuex";

export default {
    name: "RelationshipRentList",
    props: ["payableReceivableId"],
    components: {
        ScrollBar, Button
    },
    data() {
        return {
            urlGetApi: "/api/v1/finance/relationship-rent/get-all",
            urlDelete: "/api/v1/finance/relationship-rent/delete",
            content: {
                data: [],
                totalRecords: 0,
            },
            loading: false,
        };
    },
    mounted() {
        this.getAll();
    },
    computed: {
        ...mapGetters("generic", ["event"]),
    },
    methods: {
        ...mapMutations("generic", ["addEvent", "removeLoading"]),
        ...mapActions("generic", ["getApi", "deleteAllApi"]),
        getAll() {
            this.loading = true;
            let params = {
                url: this.urlGetApi,
                obj: {
                    any: this.payableReceivableId,
                },
            };
            this.getApi(params).then((response) => {
                this.content.data = response.content.data;
                this.content.totalRecords = response.content.totalRecords;
                this.loading = false;
            });
        },
        update(item) {
            this.addEvent({ name: "updateRelationshipRent", data: item });
        },
        remove(item) {
            let params = {
                url: this.urlDelete,
                selected: [item.id],
            };
            this.deleteAllApi(params).then((reponse) => {
                if (reponse.success) {
                    this.removeLoading([item.id]);
                    this.getAll();
                }
            });
        },
    },
    watch: {
        event: {
            handler(event) {
                if (event.name == "relationshipRentAdded") {
                    this.getAll();
                }
            },
            deep: true,
        },
    },
}

</script>

<style scoped>
.table th,
.table td {
    height: 10px !important;
    padding-left: 5px !important;
    padding-top: 7px !important;
    padding-bottom: 5px !important;
    padding-right: 5px !important;
    padding-left: 10px !important;
    border-bottom: 0px !important;
}

.title-header {
    font-size: 14px;
    color: #757d8c;
    font-weight: 400;
    text-transform: uppercase;
}

.td-checkbox {
    width: 30px;
}

.td-description {
    max-width: 600px;
}

.td-edit {
    cursor: pointer;
    background-color: #f1f4f9;
    max-width: 250px;
}

.div-btn {
    margin-top: 15px;
    margin-bottom: 20px;
}

.icon-danger {
    font-size: 20px;
    color: #f0134d;
    cursor: pointer;
}

.icon-danger:hover {
    color: #c70f40;
}
</style>
