<template>
  <div>
    <b-row>
      <b-col sm="5">
        <InputText
          title="Nome"
          field="name"
          :formName="formName"
          :required="required"
          :maxLength="50"
          :markFormDirty="markFormDirty"
          v-model="contactCustomerSupplier.name"
        />
      </b-col>
      <b-col sm="4">
        <InputText
          title="Departamento"
          field="departament"
          :formName="formName"
          :maxLength="50"
          :markFormDirty="markFormDirty"
          v-model="contactCustomerSupplier.departament"
        />
      </b-col>
      <b-col sm="3">
        <DateTime
          title="Data Nascimento"
          field="birthDate"
          format="DD/MM/YYYY"
          type="date"
          placeholder
          :formName="formName"
          :required="false"
          :markFormDirty="markFormDirty"
          v-model="contactCustomerSupplier.birthDate"
        />
      </b-col>
    </b-row>
    <b-row>
      <b-col sm="4">
        <InputText
          title="Celular"
          field="mobile"
          :mask="['(##) ####-####', '(##) #####-####']"
          :formName="formName"
          :maxLength="20"
          :markFormDirty="markFormDirty"
          v-model="contactCustomerSupplier.mobile"
        />
      </b-col>
      <b-col sm="8">
        <InputText
          title="E-mail"
          field="email"
          :formName="formName"
          :maxLength="100"
          :markFormDirty="markFormDirty"
          v-model="contactCustomerSupplier.email"
        />
      </b-col>
    </b-row>
    <b-row v-if="showButtonSave">
      <b-col sm="12">
        <div class="text-right">
          <Button
            _key="create"
            type="success"
            title="Salvar"
            :disabled="!isFormValid(formName)"
            classIcon="fas fa-save"
            size="medium"
            :clicked="create"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@nixweb/nixloc-ui/src/component/forms/InputText";
import DateTime from "@nixweb/nixloc-ui/src/component/forms/DateTime";
import Button from "@nixweb/nixloc-ui/src/component/forms/Button";

import Contact from "@/components/modules/shared/contact/Contact.js";

import { mapGetters, mapActions, mapMutations } from "vuex";

export default {
  name: "ContactCreateUpdate",
  props: {
    customerSupplierId: String,
    registerInSelect: Boolean,
    required: {
      type: Boolean,
      default: true,
    },
    showButtonSave: {
      type: Boolean,
      default: true,
    },
    markFormDirty: Boolean,
    value: Object,
  },
  components: { Button, InputText, DateTime },
  data() {
    return {
      formName: "contactCreateUpdate",
      contactCustomerSupplier: new Contact(),
      urlCreate: "/api/v1/shared/contact/create",
      urlUpdate: "/api/v1/shared/contact/update",
    };
  },
  computed: {
    ...mapGetters("validation", ["isFormValid"]),
    ...mapGetters("generic", ["event"]),
  },
  methods: {
    ...mapActions("generic", ["postApi", "putApi"]),
    ...mapMutations("generic", [
      "removeLoading",
      "removeEvent",
      "hideModal",
      "hideVodal",
    ]),
    ...mapMutations("validation", ["resetValidation"]),
    create() {
      this.contactCustomerSupplier.customerSupplierId = this.customerSupplierId;
      if (this.contactCustomerSupplier.id) {
        let params = { url: this.urlUpdate, obj: this.contactCustomerSupplier };
        this.putApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          this.removeLoading(["create"]);
        });
      } else {
        let params = { url: this.urlCreate, obj: this.contactCustomerSupplier };
        this.postApi(params).then((response) => {
          if (response.success && !this.registerInSelect) this.hideModal();
          if (response.success && this.registerInSelect) {
            this.$emit("input", {
              id: response.content.id,
              content: response.content.name,
            });
            this.hideVodal(this.formName);
          }
          this.removeLoading(["create"]);
        });
      }
    },
    update(data) {
      this.contactCustomerSupplier = new Contact();
      this.contactCustomerSupplier.update(data);
      this.removeLoading([data.id]);
    },
  },
  watch: {
    event: {
      handler(event) {
        if (event.name == "createContact") {
          this.contactCustomerSupplier = new Contact();
          this.resetValidation(this.formName);
        }
        if (event.name == "updateContactCustomerSupplier")
          this.update(event.data);
      },
      deep: true,
    },
    contactCustomerSupplier: {
      handler() {
        this.$emit("input", this.contactCustomerSupplier);
      },
      deep: true,
    },
  },
};
</script>
