export default class Collaborator {
    constructor() {
        this.id = "";
        this.photo = "";
        this.options = [];
        this.personRequest = { typePerson: 1, companyName: "", tradeName: "", document: "", stateInscription: "", municipalInscription: "" };
        this.contactRequest = { phone: "", webSite: "", email: "" };
        this.user = { id: "", content: "" };
        this.observation = "";
        this.status = 1;
        this.addressValueObject = {
            zipCode: "",
            street: "",
            number: "",
            complement: "",
            province: "",
            city: "",
            state: { id: "", content: "" },
        };
    }
    update(data) {

        this.id = data.id;
        this.photo = data.photo;
        if (data.isConsultant)
            this.options.push("isConsultant");
        if (data.isEmployee)
            this.options.push("isEmployee");
        if (data.isFreelance)
            this.options.push("isFreelance");
        this.personRequest = data.person;
        this.contactRequest = data.contact;
        if (data.user)
            this.user = { id: data.user.id, content: data.user.userName };
        this.observation = data.observation;
        this.status = data.status;
    }
}