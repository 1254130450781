export default class PayableReceivable {
    constructor() {
        this.id = "";
        this.type = 1;
        this.recurrence = 1;
        this.recipient = { id: "", content: "" };
        this.description = "";
        this.document = "";
        this.value = 0;
        this.dueDate = "";
        this.competenceDate = "";
        this.typePayment = { id: "", content: "" };
        this.chartOfAccounts = { id: "", content: "" };
        this.bankAccount = { id: "", content: "" };
        this.repeater = { id: "", content: "" };
        this.recurrence = 0;
        this.conciliationDate = "";
        this.discount = 0;
        this.fees = 0;
        this.paid = false;
        this.observation = "";
        this.tokenBillet = "";
    }
    update(data) {
        this.id = data.id;
        this.type = data.type;
        this.recurrence = data.recurrence;
        this.recipient = { id: data.recipient.id, content: data.recipient.content };
        this.description = data.description;
        this.document = data.document;
        this.value = data.value;
        this.dueDate = data.dueDate;
        this.competenceDate = data.competenceDate;
        this.conciliationDate = data.conciliationDate;

        if (data.typePayment.id)
            this.typePayment = { id: data.typePayment.id, content: data.typePayment.content };

        if (data.chartOfAccounts.id) {
            this.chartOfAccounts = { id: data.chartOfAccounts.id, content: data.chartOfAccounts.content };
        }

        if (data.bankAccount.id)
            this.bankAccount = { id: data.bankAccount.id, content: data.bankAccount.content };

        this.conciliationDate = data.conciliationDate;
        this.discount = data.discount;
        this.fees = data.fees;
        this.paid = data.paid;
        this.observation = data.observation;
        this.tokenBillet = data.tokenBillet;
    }
}